import React from "react"

import MainLayout from "../components/layouts/BlogLayout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>404 NOT FOUND</h1>
    <p>
      You just hit a route that doesn&#39;t exist. Come back{" "}
      <a href="/">into DeFi</a>.
    </p>
  </>
)

export default NotFoundPage
